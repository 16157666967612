body {
  background-color: #f4f8f9;
}
.heading {
  height: 100%;
}
.sign_sec {
  height: 100%;
}
.sign_hero_wrap img {
  max-width: 100%;
  width: 100%;
}
.cont_form_wrap {
  background: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 0.5px #eeeeee solid;
  height: 100%;
  padding: 30px 75px;
}
.cont_form_wrap h1 {
  color: #012269;
  font-size: 22px;
  font-family: Arial;
  font-weight: 700;
  text-transform: capitalize;
  word-wrap: break-word;
  padding: 38px 0 45px;
}
.coins___modal____header {
  height: 50px;
  width: 50px;
  display: inline-block;
  border: 5px solid #ffe602;
  border-radius: 50px;
}
.coins__modal__body {
  height: 47px;
  width: 48px;
  display: inline-block;
  background-color: #fac300;
  border-radius: 50px;
}

@media (max-width: 1399px) {
  .cont_form_wrap {
    padding: 30px 25px;
  }
  .cont_form_wrap h1 {
    padding: 30px 0;
  }
}
