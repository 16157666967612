.questions-back {
  border-radius: 10.983px;
  border: 1.831px solid var(--Foundation-Blue-blue-50, #f9fafb);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.24),
    0px 0.458px 0.915px 0px rgba(0, 0, 0, 0.24),
    0px 1.373px 1.373px 0px rgba(0, 0, 0, 0.2),
    0px 3.203px 1.831px 0px rgba(0, 0, 0, 0.12),
    0px 5.492px 2.288px 0px rgba(0, 0, 0, 0.04),
    0px 8.695px 2.288px 0px rgba(0, 0, 0, 0);
}
.questions-backColor {
  background: var(
    --dd,
    linear-gradient(0deg, #d9d9d9 0%, #012269 0.01%, #204caa 100%)
  );
}
.questions-css {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #fff;
}
.correct__answer {
  background: #7bb717 !important;
}
.wrong__answer {
  background: red !important;
}
@media (max-width: 767px) {
  .question-text {
    font-size: 14px !important;
  }
}

.right___ans____header {
  width: 36px;
  height: 36px;
  display: inline-block;
  border: 5px solid #618f15;
  border-radius: 50px;
  margin-top: 17px;
}
.right___ans____body {
  display: inline-block;
  width: 27px;
  height: 28px;
  background-color: #96c720;
  border-radius: 50px;
}
.right___ans___number {
  font-size: 12px;
  font-weight: 700;
}

.wrong___ans____header {
  width: 36px;
  height: 36px;
  display: inline-block;
  border: 5px solid #bb1010;
  border-radius: 50px;
  margin-top: 17px;
}
.wrong___ans____body {
  display: inline-block;
  width: 27px;
  height: 28px;
  background-color: red;
  border-radius: 50px;
}
.wrong___ans___number {
  font-size: 12px;
  font-weight: 700;
}

.coins____body {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-color: #05ce05 !important; /*yellow  #fac300 */
  border-radius: 50px;
}
.coins____body___2 {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50px;
}
.coins-number {
  font-size: 12px;
  font-weight: 700;
}
