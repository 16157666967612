.header__common {
  position: sticky !important;
  top: 2vh !important;
  z-index: 1000;
}
.toast-container {
  z-index: 9999;
}
.header__loggedUsername {
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
}
.header__loggedUseremail {
  font-size: 10px;
  font-weight: 600;
}

.header__dropdownMenu {
  list-style: none;
  text-align: left;
}
.header__dropdownMenuItem {
  cursor: pointer;
  padding: 10px 4rem;
}
.header__dropdownMenuItem:hover {
  background-color: #1b1d4d;
  color: white;
  border-radius: 0.5rem;
}

@media (max-width: 285px) {
  .header__loggedUsername {
    font-size: 8px;
    font-weight: 600;
    text-transform: capitalize;
  }
  .header__loggedUseremail {
    font-size: 8px;
    font-weight: 600;
  }
  .logo___img {
    height: 20px;
  }
  .profile_____img {
    height: 20px;
  }
}
