.header-section {
  background-image: url("./images/background.jpg");
  height: auto;
  background-size: cover;
  background-position: center;
  position: relative;
}
.section-one-data {
  display: flex;
}
.top-heading {
  width: 100%;
}
.text-section {
  margin-top: 10px;
  padding: 10px;
}
.text-section h1 {
  font-size: 35px;
}
.text-section p {
  font-size: 15px;
}

.landing-img img {
  width: 100%;
  height: 100%;
}

.header-section-two {
  padding: 10%;
}

.section-two-img {
  width: 100%;
  height: auto;
}
.section-two-button button {
  margin: 25px;
  background: #012267;
  color: #eeeeee;
  font-size: 16px;
  padding: 10px 20px;
}
.text-area {
  text-align: justify;
}
.text-area h1 {
  margin: 23px;
  color: #012269;
  font-size: 30px;
  font-weight: 700;
}
.text-area h2,
.text-area p {
  margin: 23px;
  color: #012269;
  font-size: 18px;
}

.header-section-three {
  padding: 4%;
  background-image: url("./images/blue-section.png");
  height: auto;
  background-size: cover;
  background-position: center;
  color: #eeeeee;
}

.text-area-three {
  margin-top: 120px;
  line-height: 20px;
}

.section-three-button {
  display: flex;
  justify-content: space-between;
}
.section-three-button button {
  background: white;
  color: #eeeeee;
  font-size: 16px;
  padding: 5px 20px;
}

.card-option {
  width: 100px;
  height: auto;
  background-color: #2a2929;
  padding: 15px;
}

.landing-section {
  padding: 50px;
}
.left-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin-top: 140px;
}

.card-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.container-card {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.card {
  /* max-width: 200px; */
  /* height: 250px; */
  text-align: center;
  background-color: #777777;
}

.card-option {
  width: 160px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.image-card {
  width: 75px;
}

/* .image-card2 {
  width: 250px;
  height: 200px;
} */

.button-content {
  display: flex;
  justify-content: space-between;
  gap: 140px;
  margin-top: 40px;
}

.custom-btn {
  background-color: #ffffff;
  color: #012269;
}
.option-section {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  margin-top: 25px;
}

.option-background {
  width: 250px;
  height: auto;
  background-color: #d9d9d9;
  display: flex;
  justify-content: center;
  color: #012267;
}
.selected-option {
  display: flex;
}
.selected-option img {
  width: 30px;
  margin-left: 20px;
}

.option-background-selected {
  width: 250px;
  height: auto;
  background-color: #012267;
  display: flex;
  justify-content: center;
  color: #eeeeee;
  margin-left: 53px;
}
.option-background p,
.option-background-selected p {
  margin-top: 10px;
  margin-right: 11px;
}

.header-section-four {
  padding: 100px;
  height: auto;
  background-size: cover;
  background-position: center;
  margin: 40px;
}

.top-content-four {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #012269;
}

.top-content-four h1 {
  font-size: 25px;
  font-weight: 700;
}

.meme-img img {
  width: 330px;
  height: 350px;
  border-radius: 20px;
  margin-top: 15px;
}

.header-section-five {
  padding: 10px;
  margin-top: 50px;
  background-image: url("./images/blue-section.png");
  height: auto;
  background-size: cover;
  background-position: center;
  color: #eeeeee;
}
.top-content-five {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}
.top-content-five h1 {
  font-size: 45px;
  font-weight: 700;
}

.questions-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.questions-box {
  width: 80%;
  background-color: white;
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}
.questions-box h5 {
  padding: 10px;
  color: #012269;
  margin-top: 10px;
}
.plus-button {
  background-color: #012269;
  color: white;
  border: none;
  padding: 5px 12px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  top: 5px;
  right: 20px;
}

.footer-container {
  width: 100%;
  height: auto;
  background-color: #1b1d4d;
}

.footer-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 165px;
  color: white;
  padding: 60px;
}
.icons-style {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.links-style {
  text-decoration: none;
  color: #eeeeee;
}
.company-details {
  width: 250px;
  font-size: xx-small;
}

.copyright-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
  padding: 20px 40px 20px 115px;
}

/* ///////Question page//////// */

.profile-gift {
  width: 60px;
}
.profile-logoo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.prof-wrap h1 {
  font-weight: 700;
  color: #012269;
}
.timer-text {
  color: white;
  font-size: 55px;
  font-weight: 600;
}

.timer-border {
  width: 70px;
  height: 70px;
  border: 10px solid #482c13;
  border-radius: 50px;
  margin-left: 10px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.timer-body {
  width: 60px;
  height: 60px;
  background-color: white;
  border: 1px dotted #482c13;
  border-radius: 50px;
  margin: 3px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.timer-style {
  width: 45px;
  height: 45px;
  background-color: #482c13;
  border-radius: 50px;
  margin: 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timer-style h5 {
  font-size: 30px;
  margin: 5px 8px;
  color: #eeeeee;
  padding: 5px;
  font-family: "Arial";
}
.question-container {
  position: relative;
}

.header {
  position: relative;
}

.main-page {
  position: relative;
  background-image: url("./images/background-gift.gif");
  height: 100vh;
  background-size: cover;
  background-position: center;
  color: #eeeeee;
  padding: 30px;
  overflow-y: hidden;
}

.question-area {
  width: 80%;
  height: auto;
  background-color: white;
  margin-left: 180px;
  margin-top: 60px;
  border-radius: 20px;
}
.question-area h4 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  color: #012269;
}

.option-area {
  width: 35%;
  height: auto;
  background-color: white;

  border-radius: 20px;
}

.option-area p {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #012269;
  margin-top: 10px;
}

.row-containers {
  display: flex;
  flex-direction: row;
}
.option-sections {
  display: flex;
  flex-wrap: wrap;
  margin-left: 30px;
  gap: 25px;
}

@media (max-width: 1024px) {
  .header-section {
    background-image: url("./images/background.jpg");
    height: 100vh;
    background-size: cover;
    background-position: center;
  }
  .section-one-data {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
  }
  .top-heading {
    width: 100%;
  }
  .text-section {
    margin-top: 20px;
  }
  .landing-img {
    margin-top: 10px;
  }
  .landing-img img {
    width: 90%;
  }
  .text-section h1 {
    font-size: 50px;
  }
  .text-section p {
    font-size: 25px;
  }
  .text-section h2 {
    font-size: 35px;
  }
}

@media (max-width: 991px) {
  .header-section {
    height: auto;
  }
  .section-one-data {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  .top-heading {
    width: 100%;
  }
  .text-section {
    margin-top: 20px;
    margin-left: 40px;
  }
  .landing-img {
    margin-top: 100px;
  }
  .landing-img img {
    width: 90%;
  }
  .text-section h1 {
    font-size: 70px;
  }
  .text-section p {
    font-size: 35px;
  }
  .text-section h2 {
    font-size: 45px;
  }
}

@media (max-width: 769px) {
  .header-section {
    background-image: url("./images/background.jpg");
    height: auto;
    background-size: cover;
    background-position: center;
  }
  .section-one-data {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  .top-heading {
    width: 100%;
  }
  .text-section {
    margin-top: 20px;
  }
  .landing-img {
    margin-top: 120px;
  }
  .landing-img img {
    width: 90%;
  }
  .text-section h1 {
    font-size: 60px;
  }
  .text-section p {
    font-size: 25px;
  }

  .footer-container {
    width: 100%;
    height: auto;
    background-color: #1b1d4d;
  }

  .footer-details {
    gap: 65px;
    padding: 30px;
  }

  .copyright-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    padding: 10px 30px 10px 40px;
  }
  .question-area {
    width: 350px;
    height: 80px;
    margin-left: 0px;
  }
  .question-area h4 {
    padding: 12px;
  }
  .option-container {
    width: 250px;
    height: auto;
    margin-left: 0px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  .option-area {
    width: 350px;
    height: 40px;
    margin-left: 0px;
  }

  .row-containers {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .option-area p {
    padding: 10px;
  }

  .button-sections {
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    margin-left: 70px; /* Change back to row for larger screens */
  }
  .user-video-screens {
    margin-right: 70px !important;
  }
}
@media (max-width: 575px) {
  .header-section {
    background-image: url("./images/background.jpg");
    height: auto;
    background-size: cover;
    background-position: center;
  }
  .section-one-data {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  .top-heading {
    width: 100%;
  }
  .text-section {
    margin-top: 20px;
  }
  .landing-img {
    margin-top: 20px;
  }
  .landing-img img {
    width: 100%;
  }
  .text-section h1 {
    font-size: 35px;
  }
  .text-section p {
    font-size: 15px;
  }
}
@media (max-width: 575px) {
  .profile-photo-sec {
    left: 40px;
    top: 200px;
    display: flex;
  }
  .profile-photo-sec img {
    width: 120px;
    height: 110px;
  }

  .main-tabs-bar {
    flex-direction: column;
  }

  .profilee-details,
  .bank-details,
  .transactions-details {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .profile-link,
  .bank-link,
  .transaction-link {
    margin: 0; /* Reset margin for small screens */
  }
  .sign-up-buttn {
    width: 100px;
  }

  .muted-icon {
    flex-direction: column;
  }
  .user-video-screens {
    width: 400px;
    height: 300px;
    position: relative;
    margin-top: 3%;
    border: 0.5px solid rgb(110, 110, 110);
    margin-right: 0px !important;
  }
}

@media (max-width: 495px) {
  .header-section {
    height: auto;
  }
  .section-one-data {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  .top-heading {
    width: 100%;
  }
  .text-section {
    margin-top: 20px;
    margin-left: 0px;
  }
  .landing-img {
    margin-top: 20px;
  }
  .landing-img img {
    width: 100%;
  }
  .text-section h1 {
    font-size: 25px;
  }
  .text-section h2 {
    font-size: 35px;
  }
  .text-section p {
    font-size: 15px;
  }

  /* ///Sect 3//// */

  .header-section-three {
    padding: 2%;
    height: auto;
  }

  .card-option {
    width: 100px;
    height: 80px;
    background-color: #2a2929;
    padding: 15px;
  }

  .landing-section {
    padding: 50px;
  }
  .left-content {
    text-align: left;
    margin-top: 80px;
  }

  .card-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
  .container-card {
    flex-direction: row;
    gap: 10px;
  }

  .card-option {
    width: 150px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  .image-card {
    width: 60px;
  }

  /* //// section 4 ///// */

  .header-section-four {
    padding: 10px;
    height: auto;
    background-size: cover;
    background-position: center;
    margin: 50px;
  }

  .top-content-four {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #012269;
  }

  .top-content-four h1 {
    font-size: 45px;
    font-weight: 700;
  }

  .meme-img img {
    width: 200px;
    height: 250px;
    border-radius: 20px;
    margin-top: 15px;
  }

  /* //////section 5////// */

  .header-section-five {
    height: auto;
  }

  .top-content-five {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .top-content-five h1 {
    font-size: 25px;
    font-weight: 700;

    text-align: center;
  }

  .questions-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .questions-box {
    width: 100%;
    padding: 3px;
  }
  .questions-box h5 {
    padding: 1px;
  }
  .plus-button {
    padding: 3px 10px;
    top: 7px;
    right: 7px;
  }

  /* //////section 6////////// */

  .footer-container {
    width: 100%;
    height: auto;
    background-color: #1b1d4d;
  }

  .footer-details {
    gap: 65px;
    padding: 30px;
  }

  .copyright-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    padding: 10px 30px 10px 60px;
  }

  .main-heading2 img {
    width: 100px;
    margin-right: 40px;
  }

  .login-buttn,
  .sign-up-buttn {
    width: auto;
    font-size: 12px;
    margin-top: 10px;
  }
  .user-video-screens {
    width: 400px;
    height: 300px;
    position: relative;
    margin-top: 3%;
    border: 0.5px solid rgb(110, 110, 110);
    margin-right: 0px !important;
  }
}

@media (max-width: 395px) {
  .header-section {
    background-image: url("./images/background.jpg");
    height: auto;
    background-size: cover;
    background-position: center;
  }
  .section-one-data {
    flex-direction: column;
    margin-left: 15px;
  }

  .text-section {
    margin-top: 5px;
  }
  .landing-img {
    margin-top: 20px;
  }
  .landing-img img {
    width: 90%;
  }
  .text-section h2 {
    font-size: 20px;
  }
  .text-section h1 {
    font-size: 20px;
  }
  .text-section p {
    font-size: 10px;
  }

  /* ///Sect 3//// */

  .header-section-three {
    padding: 2%;
    height: auto;
  }

  .card-option {
    width: 100px;
    height: 80px;
    background-color: #2a2929;
    padding: 15px;
  }

  .landing-section {
    padding: 50px;
  }
  .left-content {
    text-align: left;
    margin-top: 50px;
  }

  .card-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
  .container-card {
    flex-direction: row;
    gap: 10px;
  }

  .card-option {
    width: 150px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  .image-card {
    width: 60px;
  }

  /* //// section 4 ///// */

  .header-section-four {
    padding: 10px;
    height: auto;
    background-size: cover;
    background-position: center;
    margin: 50px;
  }

  .top-content-four {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #012269;
  }

  .top-content-four h1 {
    font-size: 45px;
    font-weight: 700;
  }

  .meme-img img {
    width: 200px;
    height: 250px;
    border-radius: 20px;
    margin-top: 15px;
  }

  /* //////section 5////// */

  .header-section-five {
    height: auto;
  }

  .top-content-five {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .top-content-five h1 {
    font-size: 25px;
    font-weight: 700;

    text-align: center;
  }

  .questions-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .questions-box {
    width: 100%;
    padding: 3px;
  }
  .questions-box h5 {
    padding: 1px;
  }
  .plus-button {
    padding: 3px 10px;
    top: 7px;
    right: 7px;
  }

  /* //////section 6////////// */

  .footer-container {
    width: 100%;
    height: auto;
    background-color: #1b1d4d;
  }

  .footer-details {
    gap: 65px;
    padding: 30px;
  }

  .copyright-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    padding: 10px 30px 10px 60px;
  }
  .main-heading2 img {
    width: 100px;
    margin-right: 40px;
  }

  .login-buttn,
  .sign-up-buttn {
    width: auto;
    font-size: 9px;
    margin-top: 10px;
  }
  .user-video-screens {
    width: 400px;
    height: 300px;
    position: relative;
    margin-top: 3%;
    border: 0.5px solid rgb(110, 110, 110);
    margin-right: 0px !important;
  }
}

@media (max-width: 268px) {
  .header-section {
    height: 60vh;
  }
  .section-one-data {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
  }

  .text-section {
    margin-top: 5px;
  }
  .landing-img {
    margin-top: 20px;
  }
  .landing-img img {
    width: 90%;
  }
  .text-section h2 {
    font-size: 20px;
  }
  .text-section h1 {
    font-size: 20px;
  }
  .text-section p {
    font-size: 10px;
  }
  .main-heading2 img {
    width: 100px;
    margin-right: 10px;
  }

  .login-buttn,
  .sign-up-buttn {
    width: auto;
    font-size: 12px;
    margin-top: 10px;
  }

  .profile-name-container h2,
  .profile-name-container p {
    margin-left: 120px;
  }
}

.score-card-lose {
  position: relative;

  background: white;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-left: 35px;
}

.score-card-winner {
  width: 120px;
  background: white;
  clip-path: polygon(49% 0%, 100% 48%, 49% 100%, 1% 48%);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  padding: 30px;
}
.score-card-lose p {
  color: red;
}
.score-card-winner p {
  color: #578115;
}
.score-card-lose p,
.score-card-winner p {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

/* .modal-content {
  width: 300px; 
  height: 500px; 
  margin: auto; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5); 
} */

.container-fluid-modal {
  position: relative;
  padding: 30px;
  background: #7bb717;
  border-radius: 10px;
}

.container-fluid-modal-ready {
  position: relative;
  padding: 55px;
  background: #7bb717;
  border-radius: 10px;
  /* clip-path: polygon(50% 82%, 100% 38%, 100% 100%, 0 99%, 0% 38%); */
}

.container-fluid-modal-win {
  width: auto;
  height: auto;
  position: relative;
  padding: 55px;
  background: #7bb717;
  border-radius: 10px;
  /* clip-path: polygon(50% 82%, 100% 38%, 100% 100%, 0 99%, 0% 38%); */
}
.container-fluid-modal-lose {
  position: relative;
  padding: 45px;
  background: #ffc3c3;
  border-radius: 10px;
}

.container-fluid-modal-meme {
  position: relative;
  padding: 15px;
  background: #7bb717;
  border-radius: 10px;
}
.win-heading {
  font-size: 25px;
  font-weight: 700;
  font-family: Arial;
  /* background: #578115; */
}
.win-heading-2 {
  font-size: 27px;
  font-weight: 700;
  font-family: Arial;
  /* background: #578115; */
}
.lose-heading {
  background: #dc3636;
}
.btn-modal {
  width: 100%;
  line-height: 19px;
  font-size: 13px;
  font-family: Arial;
  font-weight: 700;
  text-transform: capitalize;
  word-wrap: break-word;
  background: rgba(1, 34, 105, 1);
  color: #fff;
  border: 1px solid rgba(1, 34, 105, 1);
  transition: 0.3s;
}
.win-heading,
.lose-heading {
  width: 100%;
  height: auto;
  padding: 4px;
  border-radius: 10px;
  color: white;
  text-align: center;
  text-transform: uppercase;
}
.btn.btn-modal:hover,
.btn.btn-modal:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(1, 34, 105, 1);
  background: #fff;
  color: rgba(1, 34, 105, 1);
}
.emoji-style-lose {
  position: absolute;
  width: 100px;
  height: 100px;
  left: 35px;
  top: 200px;
  transform: rotate(340deg);
}
.emoji-style-win {
  position: absolute;
  left: 100px;
  top: 112px;
  z-index: 99999;
  width: 80%;
  height: 50%;
  transform: rotate(340deg);
}
.emoji-style-win-2 {
  position: absolute;
  right: 15px;
  top: 125px;
  width: 80%;
  height: 50%;
}
.ReactModal__Content {
  outline: none;
}
/* .video-area {
   margin-left: 13px; 
  text-align: center;
  width: 200px;
} */

/* //////////// USER DASHBOARD ////////////////// */

.cover-photo-sec {
  position: relative;
}

.cover-photo {
  width: 100%;
  height: 250px;
  border-radius: 20px;
  object-fit: cover;
}

.profile-photo-sec {
  position: absolute;
  left: 30px;
  top: 195px;
  display: flex;
}

.profile-photo {
  border-radius: 20px;
  height: 135px;
  width: 148px;
  object-fit: cover;
}
.profile-name-container h2 {
  margin-top: 5px;
}

.profile-name-container h2,
.profile-name-container p {
  margin-left: 190px;
}

.top-setting-sec {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
}

.text-detail-sec {
  display: flex;
  gap: 45px;
  margin-top: 40px;
  overflow-x: scroll;
}
.text-detail-sec h6 {
  font-weight: 700;
}

.password-setting-sec {
  margin-top: 20px;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.input-field-section {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 15px;
}

/* .Input-text-field {
  background-color: #f2f1f1;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: none;
  outline: none;
} */

.Input-text-field {
  background-color: #f2f1f1;
  width: 100%;
  padding: 6px 7px; /* 0px 5px */
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 15px; /* 25px */
}

.button-section {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}

.card-info-sec {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 20px;
}
.password-setting-sec h4,
.card-info-sec h4 {
  font-weight: 700;
}

.profile-link,
.bank-link,
.transaction-link {
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  color: #1e1d1d;
}
.profile-link,
.bank-link,
.transaction-link:hover {
  color: #1e1d1d !important;
}

.active {
  background: #012267;
  color: white !important;
}
.active :hover {
  background: #012267;
  color: white !important;
}

.bank-account-details {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.playing-button-sec {
  display: flex;
  justify-content: space-between;
}

.link-style {
  text-decoration: none;
  color: #ffffff;
  background-color: #012267;
  border-radius: 5px;
  padding: 4px 6px;
  font-size: 15px;
  font-weight: 500;
}

.link-style:hover {
  color: #012267;
  background-color: #ffffff;
  border: 1px solid #012267;
}

.left-side-sec h4,
.text-about-main {
  color: var(--25-Black, #252525);
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.text-about-para,
.left-side-sec p {
  color: #767676;
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.left-side-sec {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.sign-up-user {
  height: 100%;
  background: #012269;
  background-image: url("./images/bg-pic.png");
}

.dashboard-locker {
  background-image: url("./images/bg-pic.png");
}

.modal-content {
  width: 300px; /* Set your desired width */
  height: 450px; /* Set your desired height */
  margin: auto; /* Center the modal horizontally */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #578115;
  font-family: "Creepster";
  font-weight: 800;
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.9;
  z-index: 9999;
}
.custom-list {
  list-style-type: none;
  margin-left: -20px;
}
.custom-list li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.custom-list p {
  font-size: 10px;
  margin-top: 6px;
  margin: 5px;
  padding: 0px;
  font-weight: 600;
}
.btn.btn-prim-2 {
  width: 100%;
  line-height: 19px;
  font-size: 13px;
  font-family: Arial;
  font-weight: 700;
  text-transform: capitalize;
  word-wrap: break-word;
  background: rgba(1, 34, 105, 1);
  color: #fff;
  border: 1px solid rgba(1, 34, 105, 1);
  transition: 0.3s;
}
.btn.btn-prim-2:hover,
.btn.btn-prim-2:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(1, 34, 105, 1);
  background: #fff;
  color: rgba(1, 34, 105, 1);
}

/* //////////// Stringee call /////////////// */

.user-video-screens {
  width: 320px;
  height: 240px;
  position: relative;
  margin-top: 3%;
  border: 0.5px solid #000000;
  margin-right: 26px;
  background-color: #d3d3d3;
}
.player-video-screens {
  width: 320px;
  height: 240px;
  position: relative;
  margin-top: 3%;
  border: 0.5px solid #000000;
  margin-right: 26px;
  background-color: #d3d3d3;
}

/* .local-stream,
.remote-stream {
  background-color: #d3d3d3;
  height: 100%;
} */
.player-disconnect {
  position: absolute;
  top: 45%;
  left: 15%;
}
.player-offline {
  position: absolute;
  top: 50%;
  left: 13%;
}
.start-call-btn {
  position: absolute;
  top: 45%;
  left: 2%;
}

.center-wraper {
  margin-top: 110px;
}

.reconnect-call {
  width: 400px;
  height: auto;
  position: relative;
  margin-top: 5% !important;
  margin-left: 54px;
}

@media (max-width: 769px) {
  .reconnect-call {
    width: 400px;
    height: auto;
    position: relative;
    margin-top: 10% !important;
    margin-left: 20px;
  }
  .player-disconnect {
    left: 9%;
  }
  .center-wraper {
    margin-top: 110px;
    margin-right: 40px;
  }
  .player-offline {
    left: 6%;
  }
}

@media (max-width: 576px) {
  .tab-item {
    width: 75.33% !important;
  }
}

@media (max-width: 445px) {
  .reconnect-call {
    width: 400px;
    height: auto;
    position: relative;
    margin-top: 8% !important;
    margin-left: 25px;
  }
  .player-disconnect {
    right: 9%;
  }
  .center-wraper {
    margin-top: 110px;
    margin-right: 0px;
  }
  .player-offline {
    left: 9%;
  }
}
