.question-container {
  background-image: url("../../images/background-gift.gif");
  min-height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  /* clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 50% 75%, 0% 90%); */
}
.toast-container {
  z-index: 9999;
}
.header__section {
  width: 33% !important;
}
.modal___text {
  width: 100%;
  height: 40%;
}
.modal___text h6 {
  font-size: 12px;
  font-weight: 500;
}
.ads-timer-circle {
  height: 45px;
  width: 45px;
  border: 2px solid black;
  border-radius: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.coins____header {
  width: 36px;
  height: 36px;
  display: inline-block;
  border: 5px solid #ffe602;
  border-radius: 50px;
  margin-top: 10px;
}
.coins____body {
  display: inline-block;
  width: 27px;
  height: 28px;
  background-color: #fac300;
  border-radius: 50px;
}
.coins-number {
  font-size: 12px;
  font-weight: 700;
}
.circular-border {
  border: 2px solid white;
  border-radius: 50%;
  padding: 5px;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
@media (max-width: 1026px) {
  .modal___text h6 {
    font-size: 9px;
  }
}
@media (max-width: 991px) {
  .popup__menu {
    top: 3rem !important;
    right: 2rem !important;
  }
}
@media (max-width: 767px) {
  .modal___text h6 {
    font-size: 9px;
  }
  .coins____header {
    margin-top: 0px;
  }
}
@media (max-width: 575px) {
  .Ads-modal .modal___text h6,
  h3 {
    font-size: 9px;
    font-weight: 500;
  }
  .ads-timer {
    font-size: 20px;
  }
  .ads-timer-circle {
    height: 30px;
    width: 30px;
    border: 2px solid black;
    border-radius: 50px;
  }
  .coins____header {
    margin-top: 0px;
  }
}
@media (max-width: 377px) {
  .Ads-modal .modal___text h6,
  h3 {
    font-size: 9px;
    font-weight: 500;
  }
  .ads-timer {
    font-size: 20px;
  }
  .ads-timer-circle {
    height: 30px;
    width: 30px;
    border: 2px solid black;
    border-radius: 50px;
  }
  .coins____header {
    margin-top: 0px;
  }
}
